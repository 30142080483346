@import url("https://fonts.googleapis.com/css2?family=Prompt:wght@200;300;400;500;600;700&display=swap");

$main-font-family: "Prompt", sans-serif;
$second-font-family: "Sarabun", sans-serif;
$main-color:#000060;
$second-color: #bd2226;
$white-color: #ffffff;
$hover-color:#000060;
$optional-color: #f8f8f8;
$shadow-color: #676767;
$black-color: #09101f;
$primary-dark-color: #161616;
$secondary-dark-color: #202024;
$fade-dark-color: #2e2e33;
$paragraph-color: #656565;
$font-size: 16px;
$transition: 0.5s;
$font-color: #676767;
:root {
	--primary-color: #000060;
	--white-color: white;
	--black-color:  #09101f;
}
.head-seo {
	position: absolute;
	visibility: hidden;
	overflow: hidden;
  }
  
html,
body {
	margin: 0;
	padding: 0;
	font-family: $main-font-family;
	letter-spacing: 0.2px;
	overflow-x:  clip;
	@media (max-width: 475px) {
		overflow-x:  unset;
	}
}
p,
h2,
ul {
	margin-bottom: 0;
}
a {
	outline: none !important;
	text-decoration: none !important;
	color: #000;
}
section {
	padding: 30px 0;
	@media (max-width: 475px) {
		padding: 15px 0;
	}
	&.on-page{
		padding: 0px;
	}
}

.c-app {
	overflow: hidden;
}
.c-apps {
	@extend .c-app;
	height: 100vh;
	position: fixed;
}
.flex-center {
	display: flex;
	justify-content: center;
	align-items: center;
  }
.svgll {
	pointer-events: none;
	position: absolute;
	top: 0;
	left: 0;
	width: 99vw;
	height: 99vh;
	max-width: 99vw;
	max-height: 99vh;
}

.hide-pwa {
	right: -100%;
	opacity: 0;
	transition: all 0.4s ease;
}

.show-landing-page {
	overflow: hidden;
	width: 100vw;
	height: 100vh;
}

.overflow-hidden-x {
	overflow-x: hidden;
}

.container {
	width: 100%;
	margin: 0 auto;
}

.section-live {
	display: block;
	position: relative;
	width: 100%;
	height: 0;
	padding-top: 56.25%;
	text-align: center;

	iframe {
		display: block;
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
	}
}

.text-uppercase {
	text-transform: uppercase !important;
}

ul {
	display: block;
	padding: 0;
	margin: 0;

	li {
		display: inline-block;
		list-style-type: none;
		margin-right: 8px;
		cursor: pointer;
	}
}

.page-404 {
	padding: 30px 0;

	.error-box {
		margin: -50px auto;
		width: 100%;
		max-width: 250px;

		@media (max-width: 475px) {
			margin: -30px auto;
		}
	}

	.error-back-home {
		padding: 10px 20px;
		border: 2px solid #254794;
		background-color: transparent;
		color: #254794;
		font-family: "Poppins", sans-serif;
		font-size: 15px;
		font-weight: 700;
		border-radius: 45px;
		display: block;
		width: 200px;
		margin: -36px auto 40px auto;

		@media (max-width: 475px) {
			margin: 20px auto 40px auto;
		}

		&:hover {
			border: 2px solid $hover-color;
			color: $hover-color;
		}
	}
}

.no-article {
	margin: 100px auto;
	opacity: 0.3;
	-webkit-filter: grayscale(40%);
	filter: grayscale(40%);
	@media (max-width: 475px) {
		margin: 38px auto;
	}
}

.empty-article {
	display: block;
	text-align: center;

	.card-wrapper {
		text-align: start;
	}
}

.text-trim-2 {
	display: -webkit-box;
	-webkit-line-clamp: 2;
	text-overflow: ellipsis;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

.text-trim-3 {
	display: -webkit-box;
	-webkit-line-clamp: 3;
	text-overflow: ellipsis;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

@media (max-width: 1600px) {
	.container-xxl,
	.container-xl,
	.container-lg,
	.container-md,
	.container-sm,
	.container {
		max-width: 1250px;
	}
}

.error-content {
	text-align: center;
	margin: 12px auto;
	max-width: 750px;
	img {
		max-width: 150px;
		margin-bottom: 45px;
	}
	p {
		max-width: 520px;
		margin: 0 auto 25px;
		font-size: 15px;
	}
	.default-btn {
		display: inline-block;
		padding: 15px 30px;
		border-radius: 30px;
		background-color: $main-color;
		color: $white-color;
		transition: $transition;

		&:hover {
			background-color: $optional-color;
		}
	}
}

.form-group {
	margin-bottom: 1rem;
}

// .resolution-image {
// 	position: relative;
// 	// padding-top: 56.25%;
// 	// border-radius: 4px;
// 	overflow: hidden;
// 	display: block;
// 	// img {
// 	// 	position: absolute;
// 	// 	left: 0;
// 	// 	right: 0;
// 	// 	top: 0;
// 	// 	bottom: 0;
// 	// 	width: 100%;
// 	// }
// }

.fix-head {
	height: 64px;
	position: sticky;
	top: 0;
	z-index: 9999;
}
.hide {
	width: 100%;
	display: block;
	white-space: nowrap;
	overflow: hidden;
	text-indent: 1000em;
}

.img-fluid {
	display: block !important;
}

.mb-35 {
	@media (max-width: 475px) {
		margin-bottom: 1.3rem;
	}
}

#truehits_div {
	display: none;
	// display: flex;
	justify-content: center;
	align-items: center;
	background-color: $main-color;
}

.position-sticky-left-content {
	position: sticky;
	top: 210px;
	z-index: 14;
	@media (max-width: 768px) {
	  position: fixed;
	  top: 65%;
	  right: 0;
	  transform: translate(0, -65%);
	}
  }
  
  .position-sticky-left-content-js {
	position: sticky;
	top: 210px;
	z-index: 14;
	@media (max-width: 768px) {
	  position: absolute;
	  right: 0%;
	  transform: translate(0, 0);
	}
  }
  
  .back-drop {
	background-color: white;
	box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 21.38px, rgba(0, 0, 0, 0.19) 0px 32px 64px;
	width: max-content;
	padding: 5px 10px;
	border-radius: 5px;
  }
  
  .area-content {
	position: relative;
	overflow: clip;
  }