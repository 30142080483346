
.search-box {
	position: relative;
	display: flex;

	form {
		position: absolute;
		transition: all .7s .2s ease-in-out;
	  	top: -11px;
    	right: 45px;

		&.search-form {
			display: block;
			line-height: 0;
		}

		input.search-input {
			height: 42px;
			width: 700px;
			border-radius: 5px;
			font-size: $font-size;
			background-color: $white-color;
			border: 1px solid $shadow-color;
    		box-shadow: 1px 1px 3px $shadow-color;
			transition: all 1s ease-out;
			opacity: 0.96;
			padding: 4px 30px;
			box-sizing: 0 3px 10px 2px rgb(229 57 53 / 30%);

			@media (max-width: 1199px) {
				right: 25px;
				width: 750px;
			}

			@media (max-width: 1300px) {
				right: 25px;
				width: 650px;
			}

			@media (max-width: 991px) {
				right: 25px;
				width: 550px;
			}

			@media (max-width: 767px) {
				right: 25px;
    		width: 350px;
			}

			@media (max-width: 500px) {
				right: 25px;
    		width: 250px;
			}

			@media (max-width: 320px) {
				right: 25px;
    		width: 140px;
			}
		}

		&.invisible {
			opacity:0;
			animation: searchOut 700ms ease-in-out;
		}

		&.visible {
			animation: searchIn 700ms ease;
		}

	}
	button.search-btn {
		cursor: pointer;
		background-color: transparent;
		border: 0;
		padding-left: 0;
		color: $white-color;
		margin-right: -10px;
			&:hover {
				color: #5c6fc5;
			}
			svg {
				padding: 0px;
				width: 40px;
				height: 20px;
				@media (max-width:600px) {
					width: 30px;
				}
			}
		}

	.search-btn-mobile {
		border-radius: 5px;
		background: $hover-color;
		border: 1px solid $hover-color;
		color: white;
		font-weight: 600;
	}

}

/* ------------------------------ recaptcha-box ----------------------------- */

.search-wrapper {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;

	&.visible{
		position: fixed;
		background: $white-color;
		opacity: 0.8;
	}

	.background-fixed{
		display: flex;
    align-items: center;
    height: 100vh;
    justify-content: center;
	}

}
.recaptcha-box {
	position: absolute;
	height: 100vh;
	top: 0;
	left: 0;
	right: 0;

	iframe {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}
