
// -------Video NationTv
// .container {
//   @extend .container;
//   max-width: 1200px !important;
// }

.carousel-wrapper {
  .slick-slider {
    display: grid !important;
  }

  .slick-track {
    margin-left: unset;
    margin-right: unset;
  }

  .slick-prev {
    left: 25px !important;
    z-index: 10 ;
    &:before {
      font-size: 32px !important;
      opacity: 0.4 !important;
    }
    @media (max-width: 767px) {
      top: 50% !important;
      left: 10px !important;
      &:before {
        font-size: 20px !important;
      }
    }
  }

  .slick-next {
    right: 25px !important;
    &:before {
      font-size: 32px !important;
      opacity: 0.4 !important;
    }
    @media (max-width: 767px) {
      top: 50% !important;
      right: 10px !important;
      &:before {
        font-size: 20px !important;
      }
    }
  }
}