.widget-carousel-latest {
  padding: 61px 12px;
  @media (max-width: 768px) {
    padding: 36px 12px;
  }
  @media (max-width: 475px) {
    padding: 0px 12px 36px 12px;
  }
}

.show-mb {
  @media (max-width: 475px) {
    display: block!important;
  }
}

.widget-lottery-check {
  max-width: 480px;
  margin: 0px auto 30px auto;
  text-align: center;

  @media (max-width:768px){
    margin: 0 auto 0 auto;
  }
  @media (max-width:475px){
    margin: unset;
  }
  h2 {
    font-family: Prompt-Medium, sans-serif;
    font-size: 20px;
  }

  span {
    color: #c20023;
    font-size: 14px;
  }

  .custom-btn {
    width: 100%;
    padding: 6px 12px;
    color: #fff;
    background: $main-color;
    border: 1px solid $main-color;

    &:hover {
      color: $main-color;
      background-color: #fff;
      transition: all .3s ease-out;
    }

    @media (max-width: 768px) {
      margin-bottom: 30px;
    }
  }
}
.section-title {
  margin-top: 5px;
  position: relative;
  border-bottom: 2px solid $second-color;
  width: 100%;
  margin-bottom: 20px;
  &.--nonebg {
    &::after{
      border: 25px solid transparent;
    }
  }
  &::after{
    z-index: 1;
    content: "";
    top: 10px;
    left: 0px;
    position: absolute;
    border: 25px solid #fff;
    transform: rotate(0deg);
  }
}

  

  .tag-box {
    &.unset {
      line-height: 22px;
      height: 30px;
      color: white;
      background-color: transparent;
      padding: 0;
      h2 {
        font-size:26px
      }
      &:before {
        content: unset;
      }
      &:after {
        content: unset;
      }
    }
    display: inline-block;
    position: relative;
    color: black;
    border-radius: 20px;
    height: 40px;
    padding: 0px 20px;
    line-height: 40px;
    text-align: center;
    text-transform: capitalize;
    font-weight: 600;
    font-size: 14px;
    z-index: 2;
    background-color: $white-color;
    margin-bottom: 3px;
    &:before {
      z-index: 1;
      content: "";
      top: 35px;
      left: 16px;
      position: absolute;
      border-top: 28px solid $white-color;
      border-right: 28px solid transparent;
      transform: rotate(10deg);
    }
    &:after {
      content: " ";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 50px;
      padding: 2px;
      background: 
          linear-gradient(#fff 0 0) content-box,
          linear-gradient(-42deg, $second-color 47%, $white-color 49%, $white-color 52%, $main-color 54%) border-box;
      z-index: -1;
      mask-composite: destination-out; //destination-out ได้เหมือนกัน
    }
  }
  
  .line-one {
    &:before {
    z-index: 2;
    content: "";
    top: 36px;
    left: 25px;
    position: absolute;
    border-top: 23px solid $second-color;
    border-right: 24px solid transparent;
    transform: rotate(10deg);
    }
  }
  .line-two {
    height: 18px;
    z-index: 4;
    top: 38px;
    left: 16px;
    position: absolute;
    border: 1px solid $main-color;
    transform: rotate(10deg);
  }
  .line-three {
    height: 22px;
    z-index: 4;
    top: 36px;
    left: 24px;
    position: absolute;
    border: 1px solid $main-color;
    transform: rotate(50deg);
  }

.widget-lottery-wrapper {
    @media (max-width: 992px) {
      padding: 30px 0 10px 0;
    }

  .widget-lottery {
    margin: 15px auto 0px auto;
    background: #000063;
    color: #fff;
    padding: 30px;
    width: 100%;
    text-align: left;
  
    @media (max-width: 992px) {
      margin-bottom: 30px;
    }
  
    @media (max-width: 360px) {
      padding: 10px 0;
      margin-left: 0px;
      margin-right: 0px;
    }
  
    .widget-lottery-heading {
      text-align: center;
      margin-bottom: 20px;
      h3 {
        font-size: 22px;
      }
      p {
        font-size: 14px;
        color: #999999;
      }
  
      @media (max-width: 768px) {
        h3 {
          font-size: 20px;
        }
        p {
          font-size: 12px;
        }
      }
    }
  
    .widget-lottery-title {
      margin-bottom: 20px;
      h5 {
        font-size: 22px;
        color: #fff;
      }
      h6 {
        margin-top: -5px;
        font-size: 12px;
        color: #999999;
      }
  
      @media (max-width: 768px) {
        h5 {
          font-size: 20px;
        }
        h6 {
          font-size: 10px;
        }
      }
      @media (max-width: 380px) {
        h5 {
          font-size: 16px;
        }
        h6 {
          font-size: 10px;
        }
      }
    }
  
    .widget-lottery-reward {
      letter-spacing: 2px;
      h4 {
        color: #fff;
        font-size: 26px;
  
        strong {
          color: $second-color;
          font-weight: bolder;
        }
        span {
          margin-right: 10px;
        }
      }
      @media (max-width: 380px) {
        h4 {
          font-size: 18px;
        }
      }
    }
  
    a.btn-lottery {
      font-weight: 400;
      text-align: center;
      display: block;
      width: 100%;
      padding: .375rem .75rem;
      font-size: 1rem;
      line-height: 1.5;
      color: #fff ;
      background: #c20023 ;
      border:2px solid #c20023 ;
      margin-bottom: 0px ;
  
      &:hover {
        background: transparent ;
        border:2px solid #fff ;
        transition: all .5s ease-out;
      }
    }
  }
}