.category-area {
  padding-top: 10px;
  .card-v {
    margin-bottom: 5px;
  }
}
.tags-area,.search-area {
  .card-v {
    margin-bottom: 0px;
  }
}
.pt-10 {
  padding-top: 5rem;
  @media (max-width: 1024px) and (min-width: 769px) {
    padding-top: 3rem;
  }
  @media (max-width: 475px) {
    padding-top:unset;
  }
}

.latest-news {
  @media (max-width: 768px) {
    padding: 15px 12px;
    margin: 15px 0;
    background: #f1f1f1;
  }
}

.bt-0 {
   .card-v, .card-h{
    @media (max-width: 475px) {
      margin-bottom: 0px;
    }
  }
}