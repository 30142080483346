.detail-wrapper {
  @media (max-width: 768px) {
    margin: 15px auto 60px auto;
  }
}

.wrapper-interest-news .card-wrapper {
  padding: 0 10px;
}



.related-scroll {
  // padding: 0 17px;
  margin:auto;
  width: 60%;
  max-width: 100%;
  min-height: 280px !important;
  height: 280px !important;
  overflow-y: scroll !important;
  display: none;
  @media (max-width: 768px) {
    margin-top: 30px;
    display: block;
  }
  @media (max-width: 480px) {
    width: 90%;
  }
  
}
.article-share{
  ul {
    li {
      button {
        svg {
          width: 26px;
        }
      }
    }
  }
}

#lottery-detail-area{
  .category {
    min-height:30px;
  }
}
#category-area {
  .category {
    min-height:30px;
  }
}

.breadcrumb-area {
  position: relative;
  padding: 30px 0px 15px 0px;
  @media (min-width: 768px) {
    padding: 15px 0px 15px 0px;
  }
  .breadcrumb-title {
    position: relative;
    text-align: left;
    color: #fff;
    padding-top: 2px;
    // padding-bottom: 15px;
    border-bottom: 2px solid $second-color;
    &.--none-border {
      border-bottom: unset;
    }
    .badge {
      background-color: $second-color;
      font-size: 16px;
      font-weight: 500;
      border-radius: 5px;
      padding: 9px 16px;
      margin-bottom: 20px;
      height: 35px;
      a { color :$white-color; }
    }
  
    .category {
      font-size: 28px;
      font-weight: 600;
      margin-bottom: 8px;
      color: #2e2e33;
      @media (min-width: 1366px) {
        min-height: 66px;
      }
      @media (max-width: 475px) {
        min-height: 100px;
      }
    }
    .unset{
      @media (max-width: 475px) {
        min-height: unset !important;
      }
    }
  
    ul {
      height: 25px;
      display: flex;
      list-style: outside none none;
      margin: 0;
      padding: 0;
      li {
        color: rgb(73, 73, 73);
        display: inline-block;
        margin-right: 5px;
        position: relative;
        font-size: 14px;
        &:after {
          content: "-";
          font-weight: normal;
          padding-left: 5px;
        }
        a {
          font-size: 12px;
          color: #636363;
        }
  
        &:nth-last-child(1) {
          &:after {
            content: "";
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    .badge {
      font-size: 14px;
    }
    .category {
      font-size: 22px;
    }
    ul {
      li {
        font-size: 12px;
        display: contents;
        &:after {
          padding: 0 5px;
        }
        a {
          font-size: 12px;
        }
      }
    }
  }
}

.breadcrumb-head-tag {
  position: relative;
  color: #2e2e33;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 15px;
  border-bottom: 2px solid $second-color;
  // &.--none-border {
  //   border-bottom: 2px solid $second-color;
  // }

  h1 {
    font-size: 40px;
  }
  span {
    font-size: 16px;
  }

  @media (max-width: 767px) {
    display: block;
    text-align: center;
    h1 {
      font-size: 24px;
    }
    span {
      font-size: 14px;
    }
  }
}
.readpage {
  display: flex;
}
.article-area {
  width: 100%;
  padding: 0 0 20px 0;
  .slick-dots {
    bottom: -7px;
  }

  .mb-7 {
    margin-bottom: 3rem;
  }
  .content-feature-image {
    img {
      border-radius: 5px;
    }
    border-radius: 5px;
    margin-bottom: 15px;
  }
  .byline { 
    padding-top: 10px;
    span { 
      font-size: 14px;
      svg {
        margin-bottom: 2px;
      }
    }
  }
  .article-image {
    margin-bottom: 30px;
    img {
      width: 100%;
    }

    iframe {
      width: 100%;
      height: 420px;

      @media (max-width: 768px) {
        height: 360px;
      }

      @media (max-width: 475px) {
        height: 320px;
      }
    }
  }

  .article-content {
    margin-bottom: 30px;
    padding-top: 10px;
    h1 {
      font-size: 30px;
      font-weight: bold;
      word-break: break-word;
      margin-bottom: 20px;
      line-height: 1.4;
      @media (max-width: 768px) {
        font-size: 26px;
      }
    }

    .post-info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .dateUpdate {
        list-style-type: none;
        li {
          display: inline-block;
          padding-right: 10px;
          border-right: 2px solid #dcdcdc;
          line-height: 1;
          vertical-align: middle;
          font-size: 14px;
          svg {
            margin-right: 10px;
            margin-bottom: 5px;
          }
          &:nth-last-child(1) {
            border-right: none;
          }
        }
        @media (max-width: 768px) {
          li {
            font-size: 12px;
          }
        }
      }
    }
    .keypoint-wrapper {
      display: flex;
      border-top: 7px solid #000063;
      padding: 30px 0;
      margin-top: 10px;
      border-bottom: 1px dashed #000063;
  
      .keypoint-header {
        margin-top: 8px;
        p {
          color: #000063;
          font-size: 25px;
          font-weight: bolder;
          letter-spacing: 0.4px;
          line-height: 1.05;
          text-transform: uppercase;
        }
      }
      .keypoint-lists {
        overflow: hidden;
        display: flex;
        align-items: center;
      }
      ul {
        padding-left: 30px;
        @media (max-width: 767px) {
          padding-left: 15px;
          padding-top: 15px;
        }
        li {
          display: flex;
          align-items: flex-start;
  
          ::marker {
            font-size: 24px;
            line-height: 0;
            color: #000063;
          }
          list-style-type: disc;
          display: list-item;
          margin-left: 1.5rem;
          line-height: 1.65;
          font-size: 20px;
          a {
            letter-spacing: 0.2px;
            font-display: swap;
          }
        }
      }
      @media (max-width: 767px) {
        display: block;
  
        padding: 20px 0;
        margin-bottom: 30px;
        .keypoint-header {
          display: flex;
          p {
            font-size: 22px;
          }
          margin-top: 0px;
        }
        ul {
          li {
            font-size: 16px;
          }
        }
      }
    }
    .content-blurb {
      margin: 30px 0;
      background: $optional-color;
      width: 100%;
      display: inline-block;
      padding: 30px;
      position: relative;
      font-size: 18px;
      line-height: 30px;
      font-style: italic;
      border-left: 4px solid $second-color ;
      border-radius: 0px 5px 5px 0px;
      color: #565656;
    }
    .custom-padding {
      @media (max-width: 992px) {
        padding: 8px 12px 0px 0px;
      }
    }
    .mobile-solr-item{
      @media (max-width: 992px) {
        .slick-next {
          right: 0px !important;
          z-index: 0 !important;
          display: none !important;
        }
      }
    }
    .content-detail {
      font-family: $second-font-family;
      font-size: 16px;
      line-height: 1.8;
      font-weight: 300;
      letter-spacing: 0.25px;
      word-break: break-word;
      h2 {
        font-size: 18px;
        display: block;
        margin: 10px 0;
        font-size: 16px;
        font-weight: 500;
        width: 95%;
        line-height: 1.8;
        border-left: 8px solid #000063;
        background: linear-gradient(90deg, #f8f8f8 85%, white 100%);
        padding: 15px 15px 15px 35px;
        border-radius: 4px;
      }
      strong,
      span {
        font-weight: 700;
      }
      img {
        max-width: 100%;
        margin: 20px auto;
        height: auto !important;
        width: 100% !important;
      }
      a {
        color: $paragraph-color;

        &:hover {
          color: $main-color;
        }
      }
      iframe {
        position: relative !important;
        margin: 10px auto !important;
        max-width: 100%;
        display: flex;
        min-height: 400px;
        @media (max-width:475px) {
          min-height: unset;
        }
      }

      ul {
        margin-left: 50px;

        li {
          list-style-type: circle;
          display: list-item;
          a { 
            font-family: "Prompt", sans-serif;
            letter-spacing:0.2px;
          }
        }
      }

      blockquote > p, blockquote > span, blockquote > strong {
        background: aliceblue;
        padding: 30px;
        margin: 10px;
        border-left: 3px solid #479be4;
        @media (max-width: 768px) {
          padding: 15px 20px;
        }
      }

      picture {
        span {
          width: 100%;
          display: block;
          position: relative;
          text-align: center;
          font-size: 13px;
          font-weight: 300;
          line-height: 1.6;
          color:rgb(117, 117, 117);
          // color: $white-color;
          // background-color: $main-color;
          // border-radius: 0px 0px 5px 5px;
          padding: 10px;
          margin: -5px auto 15px 0px;
        }
      }
      .image-caption {
        font-weight: 400;
        font-size: 13px !important;
        font-style: italic;
        letter-spacing: .25px;
      }
      .affaliate-image {
        width: 80% !important;
      }

      // iframe dailymotion
      div[style="position:relative;padding-bottom:56.25%;height:0;overflow:hidden;"] > iframe {
        display: block;
        position: relative !important;
        margin: 20px auto !important;
        max-width: 100% !important;
        height: 480px;
        @media (max-width: 767px) {
          height: 190px !important;
        }
      }
      div[style="position:relative;padding-bottom:56.25%;height:0;overflow:hidden;"] {
        height: 480px !important;
        @media (max-width: 767px) {
          height: 200px !important;
        }
      }
      // iframe dailymotion end
    }
    .twitter-tweet {
      margin: auto;
    }
  }
}
.btn-affaliate {
  margin: auto;
  max-width: 320px;
  padding: 3px 15px;
  border-radius: 4px;
  box-shadow: 2px 3px 7px rgb(0, 0, 0, 0.20);
}

.lottery-result-box {
  padding: 20px;
  margin-bottom: 20px;
  background-color: #ececec;
  border-radius: 0.25rem;
  text-align: center;

  h3,
  h4,
  h5 {
    margin-bottom: 0px;
  }
  h3 {
    font-family: Prompt-SemiBold, sans-serif;
    font-size: 28px;
  }
  p {
    color: #bb915b;
    margin-top: 5px;
  }

  .lottery-result-list {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
    margin: 20px auto;
    border-bottom: 1px solid #bfbfbf;
    padding: 10px;

    h4 {
      font-size: 24px;
      font-family: Prompt-Medium, sans-serif;
    }
    h5 {
      font-size: 14px;
      svg {
        font-size: 20px;
        margin-left: 10px;
        vertical-align: sub;
      }

      span {
        font-family: Prompt-SemiBold, sans-serif;
        font-size: 18px;
      }
    }

    .lottery-result-item {
      &.--full {
        width: 100%;
        text-align: end;
      }

      @media (max-width: 540px) {
        width: 100% !important;
        margin-bottom: 15px;
        &.--full {
          text-align: center;
        }
      }
    }
  }
}

.lottery-detail-wrapper {
  @extend .detail-wrapper;

  a:hover {
    color: #fff !important;
    transition: 0.5s;
  }
  .lottery-image {
    .lazyload-wrapper img {
      max-width: 100%;
      height: auto;
      display: inline-block;
    }
  }

  .lottery-dark-box {
    background: #000;
    color: #fff;
    margin-top: 20px;
    padding: 30px;
    border-radius: 0.5rem;

    @media (max-width: 480px) {
      padding: 15px 15px 0 15px;
      border-radius: 0;
    }

    .lottery-box-item-heading {
      text-align: center;
      margin-bottom: 20px;
      color: #fff100;

      h1 {
        font-size: 22px;
        span {
          font-size: 14px;
          font-family: Prompt-ExtraLight, sans-serif;
          color: #999999;
        }
      }

      @media (max-width: 768px) {
        h3 {
          font-size: 20px;
        }
        p {
          font-size: 12px;
        }
      }
    }

    .lottery-box-item-title {
      h3 {
        font-size: 22px;
        color: #fff;
      }

      h4 {
        margin-top: -5px;
        font-size: 12px;
        font-family: Prompt-ExtraLight, sans-serif;
        color: rgb(226, 226, 226, 0.16)!important;
      }

      @media (max-width: 768px) {
        h5 {
          font-size: 20px;
        }
        h6 {
          font-size: 10px;
        }
      }
    }

    .lottery-box-item-reward {
      font-family: Prompt-SemiBold, sans-serif;
      letter-spacing: 2px;

      h4 {
        color: #fff;
        font-size: 26px;

        strong {
          color: $second-color;
          font-weight: bolder;
        }
        span {
          margin-right: 10px;
        }
      }
    }
  }

  .lottery-light-box {
    @extend .lottery-dark-box;
    background: #f4f4f4;
    color: #000;
    margin: 20px auto;
    text-align: center;

    .lottery-box-item-title {
      margin-bottom: 20px;

      h5 {
        color: #000;
      }

      h6 {
        color: #3d4444;
      }
    }

    .lottery-box-item-reward {
      font-family: Prompt-Light, sans-serif;
      color: #3d4444;
      padding: 10px 5px;
      line-height: 32px;
    }
  }
}

// TAG หน้าอ่าน ********************************
.content-tags {
  font-size: 14px;
  margin-top: 20px;
  margin-bottom: 20px;
  // @media (max-width: 992px) {
  //   margin-top: 0;
  // }
  span {
    border-radius: 5px;
    padding: 5px 10px;
    background-color: #000;
    color: #ffffff;
    margin-right: 5px;
  }
  a {
    display: inline-flex;
    border: 1px solid #dcdcdc;
    border-radius: 5px;
    padding: 4px 8px;
    color: #444444;
    margin: 5px 5px 0 0;
    @media (min-width: 475px) {
      margin: 10px 5px 0 0;
    }
    &:hover {
      color: $white-color !important;
      background-color: $main-color;
      border: 1px solid $second-color;
    }
  }
}
//*****************************************************************************************************************************
// TAG หน้าแรก********************************
.tags-list { 
  background-color: #fafafa;
  margin: 15px -5px;
  padding: 10px;
  border-radius: 5px;
  border-left: 10px solid $main-color;

  @media (max-width: 768px) {
    margin: 15px 0;
  }

  h2 { 
    width: fit-content;
    font-size: 1.8rem;
    text-transform: uppercase;
    padding: 0 0 0 5px;
  }

  ul {
    margin-top: 8px;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    padding-left: 0;

    li { 
      margin: 5px;
      border: 2px solid #000;
      padding: 3px 10px;
      border-radius: 5px;
      background: transparent;
      font-size: 14px;
        a {
          color: $main-color;
        }

        &:hover {
          background-color: $main-color;
          border: 2px solid $second-color;
            a {
              color: $white-color;
            }
        }
    }
  }
}
//*****************************************************************************************************************************

.section-sharelink{
  &.--mobile {
    @media (max-width:992px){
      display: flex;
      justify-content: center;
    }
  }
}

.lottery-detail-wrapper {
  font-size: 16px;

  .lottery-dark-box {
    background: $main-color;
    color: #fff;
    margin-top: 20px;
    padding: 30px;
    border-radius: 0.5rem;

    .lottery-box-item {
      padding: 25px 15px;
    }

    .dark-box-heading {
      padding-bottom: 15px;
      border-bottom: 1px solid $second-color;
      p {
        font-size: 24px;
        color: $white-color;
      }
      span {
        font-size: 14px;
      }
    }
    .lottery-box-item-title {
      h3 {
        font-size: 22px;
        font-weight: 300;
        color: #fff;
      }

      h4 {
        margin-top: -2px;
        font-size: 12px;
        font-weight: 300;
        color: rgba(226, 226, 226) !important;
      }

      @media (max-width: 768px) {
        h5 {
          font-size: 20px;
        }
        h6 {
          font-size: 10px;
        }
      }
    }
    .lottery-box-item-reward {
      h4 {
        color: #fff !important;
        padding: 10px 5px;
        line-height: 32px;
        span {
          margin-right: 10px;
        }
      }
    }

    @media (max-width: 480px) {
      padding: 15px 15px 0 15px;
      border-radius: 0;
    }
  }

  .lottery-light-box {
    @extend .lottery-dark-box;
    background: #f4f4f4;
    color: #000;
    margin: 20px auto;
    text-align: center;

    .lottery-box-item-title {
      margin-bottom: 20px;

      h5 {
        color: #000;
      }

      h6 {
        color: #3d4444;
      }
    }

    .lottery-box-item-reward {
      font-family: Prompt-Light, sans-serif;
      color: #3d4444;
      padding: 10px 5px;
      line-height: 32px;
    }
  }
  .lottery-reward{
    margin-top: 20px;
    img {
      border-radius: 0.5rem;
      width: 100%;
      min-width: 100%;
    }
  }
}

.privacy-detail {
  font-size: 16px;
  line-height: 2.5;
  font-weight: 300;
  h1 {
    margin: 30px auto;
    font-size: 30px;
  }
  b, h2 {
    font-size: 24px;
    line-height: 1.5;
    font-weight: 500;
  }
  ul {
    margin-left: 50px;

    li {
      list-style-type: circle;
      display: list-item;
    }
  }
}

.accept-cookie {
  z-index: 2;
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  .detail-cookie {
    display: flex;
    width: 100%;
    max-width: 728px;
    padding: 16px;
    line-height: 1.5715;
    word-wrap: break-word;
    background: rgba(110, 110, 110, 0.9);
    border-radius: 2px;
    box-shadow: rgb(0, 0, 0, 0.16) 2px 2px 2px;
    justify-content: space-between;
    align-items: center;
    .font-cookie {
      color: #fff;
      font-weight: 200;
      font-size: 14px;
      @media (max-width: 768px){
        font-size: 14px;
        padding: 0;
      }
    }
    .cookie_readmore{
      color: #fff;
      text-decoration: underline;
      font-weight: bold;
      margin-left: 4px;
    }
    .btn-custom{
      @extend .btn-danger;
      background: $main-color;
      border: 2px solid $main-color;
      &:hover{
        color: $main-color;
        background:#fff;
        border: 2px solid #fff;
      }
    }
  }
  @media (max-width: 500px){
    .detail-cookie {
      display: grid;
      padding: 10px;
      .font-cookie {
        font-size: 11px;
      }
    }
    .btn-custom{
      margin-left: 0!important;
      margin-top: 5px;
      border: none;
    }
  }
}


.embed-responsive {
  iframe {
    width: 100%;
    height: 400px;
    @media (max-width: 500px){
      height: 220px;
    }
    }
}


// accept cookie
.displayUnset {
  display:unset;
}

.sticky-cookie {
  position: sticky;
  bottom: 0;
  display: block;
  z-index: 101;
}
.ads-Postartice {
  display: flex ;
  justify-content: center ;
  align-items: flex-end ;
  }
.fixed{
  @media (max-width: 768px){
    position:fixed !important;
  }
}


// Video_page
.video-iframe {
  iframe {
    display:flex;
    height:100vh;
    width:100vw;
    box-sizing: border-box;
  }
}

.no-video {
  font-size: 48px;
  display:flex;
  justify-content: center;
  align-items: center;
  @media (max-width:475px){
    font-size: 20px;
  }
}


// fetching
.fetching {
  display: flex;
  justify-content: center;
  align-items: center;
}

#lottery-area{
  .category{
    min-height: 34px;
  }
}



// content feed readmore
.section-readmore {      
  background: linear-gradient(180deg, rgba(238,243,246,1) 95%, rgba(255,255,255,1) 100%);
  padding-bottom: 30px;
  .block-readmore-content {
    .post-info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 10px 0;
      .meta {
        display: flex;
        .byline {
          margin-right: 10px;
        }
        p, span, svg {
          color: rgba(0,0,0,0.6);
          font-size: 14px;
        }
      }
    
      @media (max-width: 768px) {
        margin: 5px 0px 10px 0;
        .meta {
          display: block;
          margin-top: 10px;
            .byline, .date-time {
              margin-bottom: 5px;
            }
            p, span, svg {
              font-size: 12px;
            }
            .pageview {
              justify-content: flex-start;
            }
          }
        }
      @media (max-width: 475px) {
        .byline, .date-time {
          padding-bottom: 5px;
        }
        .pageview {
          justify-content: flex-start;
        }
      }
    }
  }
  .article-read-more:not(:nth-child(1)){
    margin-top:45px;
  }
  .article-read-more:not(:last-child){
    margin-bottom:0px;
  }
 
  .article-read-more {
    border-radius: 3px;
    background-color: #fff;
    padding: 15px 15px 20px 15px;
    margin-bottom:20px;
    min-height: 300px;
    max-height: 900px;
    position: relative;
    overflow: hidden;
    .post-info{
      justify-content: flex-start;
      .meta {
        p, span, svg {
          font-size: 14px;
        }
      }
    }
    .button-readmore {
      position: absolute;
      bottom: 20px;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 2;
      // background-color: #fee600;
      // border: 2px solid #fee600;
      background-color:#000060;
      border: 2px solid #c20023;
      border-radius: 2px;
      padding: 5px 20px;
      cursor: pointer;
      transition: all 0.5s;
      &:hover {
        background-color: rgba(255,255,255, 0.95);
        border: 2px solid#000060;
        &:hover {
          a {
            color: #c20023
          };
        }
      }
      a {
        transition: all 0.5s;
        color: #ffffff;
        font-size: 16px;
        font-weight: 500;
      }
    }
    .fade-content-readmore {
      position: absolute;
      height: 60%;
      width: 100%;
      background-image: linear-gradient(to top, #fffffff4 , #ffffff00);
      bottom: 0;
      z-index: 1;
    }
    .breadcrumb-area-readmore {
      .breadcrumb-title-readmore {
        h3 {
          margin: 0;
          font-size: 28px;
        }
        @media (max-width: 768px){
          h3 {
            font-size: 24px;
          }
        }
      }
    }
    
    .content-feature-image-readmore {
      .resolution-image{
        border-radius: 3px;
      }
      .embed-responsive {
        iframe {
          width: 100%;
          height: 400px;
          @media (max-width: 500px){
            height: 220px;
          }
          }
        }
    }
    .content-blurb {
      font-size: 18px;
    }
  }
  .background-article {
    background: #f5f5f5;
    padding: 1rem;
    border-radius: 0.25rem;
    &.--white {
      background: #fff;
    }
  }
}
