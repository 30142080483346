.section-wrapper {
  width: 100%;
  margin: 0px;
  overflow-x: hidden;

  &.--bg-accent {
    background-color: #f8f8f8;
  }

  &.--bg-main {
    background-color: $main-color;
      h3, span, svg {
        color: #FFF;
      }
  }

  .tab-space1 {
    margin-left: -1px;
    margin-right: -1px;
    > div {
      padding: 0 1px;
    }
  }

  .mt-8 {
    margin-top: 8px;
  }
  .mb-30 {
    margin-bottom: 30px;
  }


  // ------- Section Title ---------
  .section-title {
    &.--breaking-news-bg {
      border-bottom: 2px solid $white-color;
      
      .tag-box{
        padding: unset;
        height: 34px;
        margin-top: 2.8rem;
        @media (max-width: 992px) {
          margin-top: 2rem;
          height: 46px;
        }
        &::after {background-color: unset;}
        &::before {border-top: unset;}
        h2, a {
          font-size: 2.5rem;
          text-transform: uppercase;
          font-weight: 700;
          @media (max-width: 992px) {
            font-size: 3.3rem;
          }
          @media (max-width: 600px) {
            font-size: 2rem;
          }
        }
      }
    }
  }
  // ------- end Section Title ---------

  .tag-box {
    h2, a {
      font-size: inherit;
      line-height: inherit;
      color: inherit;
    }

    &.top-left {
      position: absolute;
      top: 10px;
      left: 10px;
    }

    &.--sm {
      height: 20px;
      padding: 0 10px;
      line-height: 20px; 
      font-size: 12px;
      @media (max-width:500px){
        font-size: 10px;
        padding: 0 6px;
      }
    }
  }

  .section-full-tab {
    .tab-body {
      @media (max-width: 767px) {
        margin-top: 80px;
      }
    }
  }

  .title-breaking-news { 
    right: 0 !important;
    &.desktop {
      @media (max-width: 767px) {
        display:none;
      }
    }
    &.mobile{
      display: none;
      @media (max-width: 767px) {
        display: block;
      }
    }
    @media (max-width: 767px) {
      margin-bottom: 1.3rem;
    }
    .breaking-readmore {
      margin-top: 2.5rem;
      padding: 8px 0;
      @media (max-width: 992px) {
        margin-top: 2.5rem;
      }
      @media (max-width: 767px) {
        display: flex;
        justify-content: center;
        color: #fff;
        border: 1px solid #fff;
        padding: 4px 5px;
        margin-top: 0;
        border-radius: 6px;
      }
    }
    a {
      color: $white-color;
      &:hover {
        color: #cecece !important;
      }
    }
  }
  // Title Section Tab
  .btn-title-tab {
    position: absolute;
    z-index: 1;
    right: 10px;
    top: 5px;
    .current {
      color: $main-color;
    }
    a {
      cursor: pointer;
      background: transparent;
      padding: 5px 6px;
      margin: 0;
      display: inline-block;
      &:hover {
        color: $main-color;
      }
    }
    @media (max-width: 767px) {
      top: 0;
      left: 0;
      right: 0;
      margin-top: 70px;
      .current {
        border: 1px solid $main-color;
      }
      a {
        border-radius: 6px;
        color: $shadow-color;
        border: 1px solid $shadow-color;
        margin-right: 5px;
      }
    }
  }
  // End Sction Tnews Page ----------------------------------------------------------------
}
  // bg-breaking-news------------------------------------
  .bg-breaking-news {
    position:relative;
    background: #c20023;
    margin-bottom: 10px;
    .tab-body {
      margin-top: 0 !important;
    }
    .card-h {
      margin-bottom: 14px;
    }
    .card-v {
      margin-bottom: 0;
    }
  }
  .bg-breaking-news-duo {
    position: absolute;
    background: white;
    width: 100%;
    height: 20%;
    bottom:0;
    right: 0;
    @media (max-width: 768px) {
      height: 43%;
    }
    @media (max-width: 600px) {
      position: unset;
      background: unset;
    }
  }
  .z-index {
    z-index: 1;
  }
  // END bg-breaking-news----------------------------

  // bg-lastest-new ---------------------------
  .bg-lastest-new {
    // background-color: #01008a; ตัวเลือก
    // background-color: #f8f8f8;
    padding: 30px 0 1px 0;

  }
  // -----------------------------------------