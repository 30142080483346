.ads-mock {
  width: 100%;
  height: 320px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ads, .ads-inarticle  {
  width: 100%;
  height: auto;
  margin: 15px auto !important;
  text-align: center;

  img, iframe{
    display:block;
    margin:auto;
  }

  &.--mt-45 {
    margin-top: 45px;
  }
}


.ads-sticky, .ads-Sticky {
  @extend .ads;
  max-width: 336px;
}
.ads-300-250 {
  @extend .ads;
  max-width: 300px;
}

.ads-320-100 {
  @extend .ads;
  max-width: 320px;
}

.ads-970-250 {
  @extend .ads;
  max-width: 970px;
}

.ads-728-90 {
  @extend .ads;
  max-width: 728px;
}

.inarticle {
  text-align: center;
}

.ads-sticky-overflow {
  width:100%;
  text-align: center;
  position: sticky;
  bottom: 0;
  z-index: 3;
}

.ads-postartice {
  width:100%;
  text-align: center;
  position: sticky;
  bottom: 0;
  left: 0;
  z-index: 9999;

}

.ads-billboard {
  width: 100%;
  height: auto;
  margin: 20px auto !important;
  justify-content: center;
  display: flex;
  @media (max-width: 768px) {
    margin: 5px auto !important;
  }
}

// .default-inread {
//   min-height: 110px;
//   margin: 20px auto !important;
//   justify-content: center;
//   display: flex;
// }

.ads-head-stories{
  padding-top: 64px;
}

.default-billboard {
  min-height: 250px;
  @media (max-width: 475px) {
    min-height: 300px;
  }
}
.default-sticky {
  min-height: 310px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ads-sticky2 {
  min-height: 310px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.default-inread {
  min-height: 110px;
  margin: 15px auto!important;
  @media (max-width: 475px) {
    min-height: 310px;
  }
}