footer {
  border-top: 2px solid $second-color;
  background-color: $main-color;
  color: $white-color;
  width: 100%;

  .footer-wrapper {

    /* ------------------------------- FOOTER TOP ------------------------------- */
    section.footer-top {
      /* ------------------------------- footer-box ------------------------------- */
      .footer-box {
        width: 100%;
        padding: 0 15px;

        .footer-title {
          color: $white-color;
          font-weight: 600;
          font-size: 16px;
          margin-bottom: 30px;

          .bar-left {
            content: '';
            height: 2.5px;
            width: 50px;
            bottom: -5px;
            left: 0;
            z-index: 5;
            background-color: $second-color;
          }
        }

        /* ----------------------------- footer-list ----------------------------- */
        .footer-box-content {
          width: 100%;
          font-size: 14px;
          font-weight: 400;
        }

        /* ----------------------------- footer-list ----------------------------- */
        .footer-list {
          display: flex;
          width: 100%;
          font-size: 14px;
          font-weight: 400;

          .popular-categories {

            li {
              position: relative;
              padding-bottom: 10px;
              margin-right: 20px;
              margin-bottom: 10px;
            }

            li a {
              display: block;
              color: $white-color;
              text-transform: capitalize;
              text-decoration: none;
              transition: all 0.5s ease-out;
            }

            li:hover:before {
              width: 100%;
            }

            li:before {
              content: '';
              background-color: $second-color;
              width: 0%;
              height: 1px;
              left: 0;
              bottom: 1px;
              position: absolute;
              transition: all 0.5s ease-out;
            }
          }
        }
      }


    }

    /* ------------------------------- FOOTER BOTTOM ------------------------------- */
    section.footer-bottom {
      border-top: 2.5px solid $second-color;
      background: $main-color;

      /* ---------------------------------- logo ---------------------------------- */
      .footer-logo {
        padding-bottom: 40px;

        img {
          max-width: 100%;
          width: 180px;
          -webkit-filter: drop-shadow(0px 1px 1px #000);;
          filter: drop-shadow(0px 1px 1px #000);;
        }
      }

    }

    /* -------------------------------- copyright ------------------------------- */
    .footer-copyright 	{
      background: $main-color;
      padding: 20px 0;

      span {
        font-size: 12px;
        color: $white-color;

        @media (max-width: 425px) {
          font-size: 10px;
        }
      }

      img {
        display: inline-block;
        max-width: 50px;
      }
    }

    .footer-grid 	{
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: 1fr;
      gap: 10px 10px;

      @media (max-width: 900px) {
        grid-template-columns: repeat(2, 1fr);
      }
      @media (max-width: 480px) {
        grid-template-columns: repeat(1, 1fr);
      }
    }

    .footer-item {
      width: 100%;
      display: flex;
      justify-content:center;
      align-items: center;
    }

  }
}

/* --------------------------------- social --------------------------------- */
.footer-social	{
  svg {
    height: 38px;
    width: 38px;
    padding: 6px;
    background-color: $paragraph-color;
    border-radius: 5px;
    color: $white-color;
    transition: .3s;
    &:hover {
      background-color: $second-color;
    }
  }
}





















