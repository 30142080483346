@keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@keyframes fadeOut {
  0% {
    opacity:1;
  }
  100% {
    opacity:0;
  }
}

@keyframes searchIn { 
  from { 
    transform: translateX(10px); 
  } 
  to {
    transform: translateX(0px);
  }  
}

@keyframes searchOut { 
  0% {
    opacity:1;
    transform: translateX(0px); 
  }
  100% {
    opacity:0;
    transform: translateX(10px); 
  }
}

@keyframes slideIn { 
  from { 
    transform: translateX(200px); 
  } 
  to {
    transform: translateX(0px);
  }  
}

@keyframes slideOut { 
  from { 
    transform: translateX(0px); 
  } 
  to {    
    transform: translateX(200px);
  }  
}

