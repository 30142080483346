
.card-wrapper {
  position: relative;

  .rm-heading {
    &:before {
      border-top: unset;
    }
  }
  .tag-box {
    &:after {
      background-color: $hover-color;
    }
  }
  .custom-padding{
    padding-right: 9px;
    @media (max-width: 1024px) {
      padding-right: 0;
      padding-left: 0;
    }
  }

  .with-inline-block{
    display: inline-block;
  }

  &.--bgfff{
    background-color: rgba(255, 255, 255, 0.747);
  }
  .p-15{
    padding:0 15px;
  }
  .fw {
    color: #fff !important;
  }
  // Start Style Tnews Pages -------------------------------------------------------------------------

  .card-h {
    cursor: pointer;
    margin-bottom: 20px;
    overflow: hidden;
    img {
      width: 100% !important;
    }
    // BreakingNews
    .custom-font {
      color: #fff;
    }
    // -----------
    h3 {
      font-size: 16px;
      font-weight: 400;
      color: black;
      @extend .text-trim-3;
    }
    &:hover {
      h3 {
        color: $main-color;
        &.custom-font {
          color: rgb(206, 206, 206);
        }
      }
    }

    &.--white {
      h3 {
        color: white ;
        &:hover {
          color: $hover-color !important;
        }
      }
    }

  }

  .card-h-nav {
    display: flex;
    width: 100%;
    margin-bottom: 15px;
    .thumbnail {
      img{
        object-fit: cover;
        // border-radius: 5px;
      }
    }
    .title {
      padding-top: 5px;
      padding-bottom: 5px;
      h3 {
        font-size: 14px;
        color: $black;
        margin-bottom: 0;
        @extend .text-trim-2;
      }
      svg {
        margin-left: 0;
        color: $secondary-dark-color;
      }
      span {
        font-size: 12px;
        margin-left: 5px;
        color: $secondary-dark-color;
      }
    }
    &:hover {
      h3, a {
        color: $hover-color !important;
      }
      svg, span {
        color: $shadow-color !important;
      }
    }
  }

  .card-v {
    cursor: pointer;
    margin-bottom: 20px;
    @media (max-width: 1024px) {
      margin-bottom: 0;
    }
    @media (max-width: 475px) {
      margin-bottom: 10px;
    }
    &.mb-unset{
      margin-bottom:unset;
    }

    img{
      width: 100%;
      height: auto;
    }

    .thumbnail-img {
      position: relative;
    }

    h3 {
      font-size: 16px;
      font-weight: 500;
      line-height: 1.5;
      color: black;
      @extend .text-trim-2;
    }
    &:hover {
      h3 {
        color: $main-color;
      }
    }

    &.--white {
      h3 {
        color: white;
        margin-top: 10px;
      }
      .blurb {
        display: block;
        color: white;
        font-size: 14px;
        font-weight: 200;
        margin-top: 10px;
      }
      &:hover {
        h3 {
          color: $hover-color;
        }
        .blurb {
          color: $hover-color;
        }
      }
    }
    .custom-font-card-v {
      h3 {
        font-size: 24px;
      }
    }
    .breaking-color {
      color: #fff;
      h3 {
        line-height: 1.65;
        color: #fff;
      }
    }
  }

  .play-btn {
    position: absolute;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    left: 50%;
    right: 0;
    display: block;
    margin: 0 auto;
    z-index: 0;
    -webkit-transition: all 0.5s ease-out;
    -moz-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
    text-align: center;
    img {
      width: 44px;
      margin: auto;
    }
  }

  .img-overlay-70 {
    position: relative;
    cursor: pointer;
    &:before {
      opacity: .7;
      background-image: -webkit-gradient(
        linear,
        0 0,
        0 100%,
        from(#111),
        to(transparent)
      );
      background-image: -webkit-linear-gradient(transparent, #111);
      background-image: -moz-linear-gradient(transparent, #111),
        -moz-linear-gradient(transparent, #111);
      background-image: -o-linear-gradient(transparent, #111),
        -o-linear-gradient(transparent, #111);
      background-image: linear-gradient(transparent, #111),
        linear-gradient(transparent, #111);
      content: "";
      height: 40%;
      bottom: 0;
      left: 0;
      right: 0;
      position: absolute;
      width: 100%;
      z-index: 1;
    }
  }

  .img-scale-animate {
    overflow: hidden;
    // border-radius: 5px;

    &:hover {
      img {
        -webkit-transform: scale(1.1);
        -ms-transform: scale(1.1);
        transform: scale(1.1);
      }
      h3 {
        color: #cecece;
      }
    }
    img {
      -o-transition: all 1.5s ease-out;
      transition: all 1.5s ease-out;
      object-fit: cover;
      // border-radius: 5px;
    }

    h3 {
      font-size: 16px;
      font-weight: 500;
      line-height: 1.4;
      color: #ffffff;
      @extend .text-trim-2;
    }

    &.--width-small {
      width: 33.33%;
    }

    &.--large {
      .resolution-image {
        position: static;
        padding-top: 0px;
      }

      img {
        height: 440px;
      }
      h3 {
        font-size: 28px;
      }

      @media (max-width:767px){
        height:260px;

        img {
          height: 260px;
        }
      }
    }

    &.--height400 {
      height: 390px;
      .resolution-image {
        position: static;
        padding-top: 0px;
      }

      img {
        height: 390px;
      }
      h3 {
        font-size: 22px;
      }

      @media (max-width:767px){
        height: 360px;

        img {
          height: 360px;
        }
      }
    }

    &.--height500 {
      height: 500px;
      .resolution-image {
        position: static;
        padding-top: 0px;
      }

      img {
        height: 500px;
      }
      h3 {
        font-size: 22px;
      }

      @media (max-width:767px){
        height: 240px;

        img {
          height: 240px;
        }
      }
    }
  
    &.--small {
      height: 220px;
      .resolution-image {
        position: static;
        padding-top: 0px;
      }

      img {
        height: 220px;
      }
      h3 {
        font-size: 16px;
      }

      @media (max-width:767px){
        height: 220px;
        img {
          height: 220px;
        }
      }
    }
  }

  .width-title {
    width: 66.66%;
  }

  .mask-content-lg {
    position: absolute;
    z-index: 7;
    bottom: 10px;
    left: 15px;
    padding-right: 15px;
  }

  .post-date-light {
    color: #fff;
    margin-top: -10px;
    margin-bottom: 0px;
    width: fit-content;
    padding: 3px 6px;

    ul {
      li {
        svg {
          font-size: 12px;
        }
        span {
          margin-left: 5px;
          line-height: 1;
          font-size: 12px;
          vertical-align: middle;

          @media (max-width: 1199px) {
            font-size: 12px;
          }
        }
      }
    }
  }

  ul {
    list-style: outside none none;
    margin: 0;
    padding: 0;
  }

  h3 {
    font-size: 24px;
  }

  .mb-2 {
    @extend .mb-2;
    margin-bottom: 2px !important;
  }

  h2 {
    font-size: 20px;
    &.--fs-14 {
      font-size: 14px;
    }
    a {
      color: #ffffff;
      &:hover {
        color: #08f;
      }
    }
  }

  .post-date-dark {
    color: #000;
    margin-top: -5px;
    margin-bottom: 5px;

    ul {
      li {
        svg {
          font-size: 12px;
          vertical-align: middle;
        }
        span {
          margin-left: 5px;
          line-height: 1;
          font-size: 12px;
          vertical-align: middle;

          @media (max-width: 1199px) {
            font-size: 12px;
          }
        }
      }
    }
  }

  .mb-5 {
    margin-bottom: 5px !important;
  }

  .text-center {
    text-align: center !important;
  }

  // End Style Tnews Pages ------------------------------------------------------------------------------------
}
