.banner-special {
  width: 100%;
  img {
    max-width: 100%;
    width: 100%;
    margin-bottom: -7px;
  }
}

.show-desktop {
  display: block;
}

.show-mobile {
  display: none;
}

@media (max-width: 767px) {
  .show-desktop {
    display: none;
  }
  .show-mobile {
    display: block;
  }
}

// Section Event Covid
.fix-covid {
  min-height: 642px;
  @media (max-width: 475px) {
    min-height: 1190px;
  }
}
.fix-readall-covid {
  @media (max-width: 475px) {
    height:44px;
  }
}
.section-covid {
  background: url('../images/bg_coronavirus.webp') no-repeat center top;
  padding: 35px 0;
  color: #fff;
  width: 100%;
  background-size: cover;
  min-height: 640px;

  @media (max-width: 768px) {
    background: url('../images/bg_coronavirus-mobile.webp') no-repeat center top;
    background-size: cover;
  }

  .header-img {
    margin-top: 30px;
    img {
      width: 50%;
      @media (max-width: 767px) {
        width: 80%;
      }
    }
  }
  .header-readmore {
    font-size: 14px;
    background: $secondary-dark-color;
    a { color: white!important; }
    &:hover { background: linear-gradient(90deg, rgba(241,164,35,1) 0%, rgba(170,40,0,1) 100%); }
  }
  .section-covid-update {
    padding: 24px 16px;
    background: #00000069;
    .card-wrapper{
      &:hover {
        h3, .blurb {
          color: rgba(256, 256, 256, 0.7) !important;
        }
      }
    }
  }

  .section-covid-latest {
    min-height: 334px;
    padding: 20px 14px 30px 14px;
    background: #971916;

    .text-section-covid {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 24px
    }
  
    .covid-iframe{
      position: relative;
      padding-bottom: 56.2%;
      max-width: 100%;
      iframe {
        position: absolute;
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }

    .covid-count-box {
      background: rgb(0, 0, 0, 0.75);
      padding: 10px;
      border-radius: 10px;
      margin: 0;

      .box-border-right { border-right: 3px solid $second-color; }

      .text-key { font-size:14px }
      
      .count-box-top {
        display: grid;
        font-size: 12px;
        .text-value {
          font-size: 30px;
          &.--bg-left{ background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(138,39,20,1) 90%); }
          &.--bg-right{ background: linear-gradient(180deg, rgba(0,0,0,0) 10%, rgba(223,91,18,1) 100%); }
          span {
            font-size: 12px !important;
            padding: 0 6px;
          }
        }
        &:nth-last-child(1){
          // display: flex;
          justify-content: center;
          align-items: baseline;
          margin-top: 6px;
          .text-value {
            text-align: center;
            font-size: 18px;
            background: transparent;
            margin-left: 6px;
          }
        }
      }
      .count-box-bottom {
        border-bottom: 1.7px solid $second-color;
        padding: 6px 0;
        font-size: 16px;
        span{ font-size: 12px; }
        .text-value {
          font-size: 20px;
          background: linear-gradient(90deg, rgba(0,0,0,0) 10%, rgba(63,128,6,1) 100%);
        }
        &:nth-child(2) {
          .text-value {
            background: linear-gradient(90deg, rgba(0, 0, 0, 0) 10%, rgba(153,84,40,1) 100%);
          }
        }
        &:nth-last-child(1){
          border-bottom: none;
          .text-value{
            background: linear-gradient(90deg, rgba(0,0,0,0) 10%, rgba(184,56,17,1) 100%);
          }
        }
      }
    }

    .date-update {
      font-size: 12px ;
      float: right;
      margin-top: 5px;
    }
  }
}


