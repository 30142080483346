
.lazada {
  .lazada-page {
    background: linear-gradient(180deg, rgba(0, 0, 131, 1) 0%, rgba(48, 34, 243, 1) 100%);
    letter-spacing: 0px;
    color: #fff;
    font: 300 16px/1.4 'Kanit';
    width: 100%;
    overflow: hidden;
    z-index: 1;
  
    strong {
      font-weight: 400;
      line-height: 1.2;
    }
  
    .lazada-top, .container {
      position: relative;
      z-index: 2;
    }
  
    .lazada-top {
      background: #000083;
      background-size: 515px auto;
      padding: 0px 0;
      text-align: center;
      position: relative;
  
      img {
        // border-radius: 8px;
        width: 100%;
      }
  
    }
  
    .lazada-logo {
      width: 280px;
      margin: auto;
      display: block;
      padding: 30px 0;
    }
  
    .lazada-banner {
      width: 100%;
      margin: auto;
      display: block;
      .desktop {
        display: block;
      }
      .mobile {
        display: none;
      }
  
      @media (max-width: 767px) {
        .desktop {
          display: none;
        }
        .mobile {
          display: block;
        }
      }
    }
  
    .btn-lazada {
      position: absolute ;
      width: 100%;
      bottom: 10px;
      text-align: center;
      margin: 15px auto;
  
      a {
        cursor: pointer;
        text-decoration: none;
        font-size: 1.25rem;
        font-family: Prompt, sans-serif !important;
        background-color: transparent;
        color: #ffffff;
        border: 1px solid #fff;
        border-radius: 50px;
        padding: 10px 20px;
  
        &:hover, &:focus, &:active {
          background-color: #ffffff ;
          color: #000083 !important;
        }
      }
  
    }
  
    .lazada-list {
      padding: 20px 0 50px 0;
      background-size: 221px auto;
      position: relative;
  
      &:before {
        content: '';
        position: absolute;
        background: url('../images/lazada/heart.svg') no-repeat right bottom;
        background-size: 523px 380px;
        left: 0;
        bottom: 0px;
        width: 300px;
        height: 380px;
        z-index: 0;
      }
  
      &:after {
        content: '';
        position: absolute;
        background: url('../images/lazada/heart.svg') no-repeat left top;
        background-size: 523px 380px;
        right: 0;
        top: 0px;
        width: 200px;
        height: 380px;
        z-index: 0;
      }
  
      .lazada-list-h {
        font-size: 50px;
        font-family: Prompt, sans-serif !important;
        text-align: center;
        font-weight: 400;
        margin: 30px 0 40px;
  
        @media (max-width: 480px) {
          font-size: 34px;
        }
  
        @media (max-width: 320px) {
          font-size: 28px;
        }
      }
    }
  
    .lazada-ul-list {
      display: grid;
      margin: 0;
      grid-template-columns: repeat(3, 1fr);
      column-gap: 30px;
      z-index: 1;
      position: relative;
      padding: 0 15px;
      margin: auto 5%;
  
      li {
        list-style: none;
        margin-bottom: 30px;
  
        a, img {
          display: block;
          width: 100%;
          box-sizing: border-box;
          border-radius: 8px;
        }
  
        a {
          box-shadow: 0 0 15px rgba(255, 255, 255, 0.2);
          transition: ease-in-out 200ms;
  
          &:hover {
            background: #000083;
          }
        }
      }
  
      @media (max-width: 480px) {
        grid-template-columns: repeat(2, 1fr);
        column-gap: 15px;
        padding: 0 !important;
        margin: 0 !important;
      }
    }
  }
}
