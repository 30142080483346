header {
  width: 100%;
  box-shadow: 0px -16px 40px 0px rgba(0,0,0,0.8);
  section {
    padding: 0;
    margin: 0;
  }

  .header-top-fixed {
    background: $main-color;
    width: 100%;
    height: 64px;
    display: flex;
    align-items: center;
    box-shadow: 2px 2px 5px 2px rgba(191, 191, 191, .1);
    border-bottom: 3px solid $second-color;
    margin: 0 auto;
    left: 0;
    right: 0;
    z-index: 100;
  }

    .expand-more-menu {
      background-color: $main-color;
      position: absolute;
      top: 64px;
      z-index: 25;
      width: 100%;
      left: 0;
      box-shadow: 2px 5px 7px rgba(0,0,0,.1);
      .nav-width {
        width: 1200px;
        margin: auto;
      }
      ul {
        li {
          font-size: 14px;
          font-weight: 300;
          padding: 10px 4px;
          margin: 0px 5px;
          &:hover {
            background: $white-color;
            a{
              color: $main-color;
            }
          }
          a {
            color: $white-color;
            &:hover{
              span, svg { 
                color: $main-color;
              }
            }
          }
        }
      }
      &.--hide {
        display: none;
      }
    }

  .header-top {
    width: 100%;
    height: 64px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  /* ------------------------------- HEADER LOGO ------------------------------ */

  .header-logo {
    @media (max-width: 1024px) {
      order: 2;
      justify-content: center;
    }

    order: 1;
    width: 100%;
    max-width: 73px;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    img {
      padding-bottom: 12px;
      height: auto;
      padding-right: 10px;
      margin-left: 0;
      @media (max-width: 1024px) {
        padding-right: 0;
        padding-bottom: 0;
        margin-left: 15px;
      }
  
    }

    /* --------------------------------- hide H1 -------------------------------- */
    h1 {
      position: relative;
      overflow: hidden;
      margin: 0;

      span {
        position: absolute;
        width: 100%;
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-indent: 1000em;
      }
    }
    /* -------------------------------------------------------------------------- */
  }

  /* ------------------------------- HEADER MENU ------------------------------ */

  /* ------------------------------ DESKTOP : MENU ---------------------------- */
  .header-menus {
    order: 2;
    width: 100%;
    display: flex;
    justify-content: flex-start;

    .nav-mobile {
      display: none;
    }
    .nav-more { 
      position: unset !important;
    }

    nav.nav-dropdown-desktop {
      position: relative;

      /* ------------------------------- DESKTOP : Navigation Menu -------------------------------- */
      ul.nav-menu {

        li.active-menu {
          position: relative;
          padding: 18px 9px;
          svg{
            margin-left: 5px;
          }
          &:hover {
            background: #fff;
            a { color: $main-color }
          }
          a {
            color: $white-color;
            font-family: $main-font-family;
            font-size: 14px;
            font-weight: 300;

            &:hover {
              svg, span {
                color: $main-color !important;
              }
            }
          }
        }



        a {
          color: $font-color;
          font-family: $main-font-family;
          font-size: 14px;
          font-weight: 300;
        }
      }

      /* ---------------------------- DESKTOP : Navigation SubMenu ------------------------------ */
      ul.nav-sub-menu {
        background: $white-color;
        border: 1px solid $main-color;
        width: 600px;
        position: absolute;
        visibility: visible;
        top: 60px;
        z-index: 99;
        padding: 0px;
        margin: 0px;
        display: flex;
        flex-flow: column nowrap;
        opacity: 1;
        transition: all 0.3s ease-out;
        transform-origin: 0 0 0;
        box-shadow: 0 6px 9px -6px #cccccc;
        &.custom-nav-top{
          left: 0px;
          top: 39px !important;
        }
        li.active-sub-menu {
          margin: 0;
          padding: 0;
        }
        a {
          color: $font-color;
          font-family: $main-font-family;
          font-size: 14px;
          font-weight: 300;
          display: block;
          text-transform: uppercase;
          transition: all 0.5s ease-out;
        }
        &.--right {
          right: 0;
        }
        &.--left {
          left: 0;
        }
      }

    .card-sub-menu {
      padding: 15px;
      ul {
        overflow: hidden scroll;
        height: 300px;
        list-style: none;
        padding: 0;
        &::-webkit-scrollbar{
          width: 4px;
          background-color: #F5F5F5;
        }
         
        &::-webkit-scrollbar-track {
          background-color: #ffffff21;
          border-radius: 10px;
        }
         
        &::-webkit-scrollbar-thumb{
          border-radius: 10px;
          background-color:#000060c7;
        }

        li {
          color: $font-color;
          padding: 5px;
          border-radius: 3px;
          margin-bottom: 16px;
          i {
            float: right;
            color: transparent;
          }
          &:hover {
            background-color: $hover-color;
            color: $white-color !important;
            i {
              color: $white-color !important;
            }
          }
        }
      }

      &.--small {
        img {
          width: 54px;
          height: 54px;
        }
        h3 {
          font-size: 14px;
          line-height: 1.4;
        }
        p {
          font-size: 12px !important;
          font-weight: 400;
          margin: 0;
        }
      }

      .card-sub-menu-content {
        .thumbnail a {
          picture {
            width: 110px;
            object-fit: cover;
            background-size: cover;
            background-position: center center;
          }
        }

        button.btn-readmore {
          width: 100%;
          background-color: #e3e3e3;
          color: $font-color;
          border-radius: 3px;
          border: 1px solid #e3e3e3;
          padding: 5px 80px;

          a.see-all:hover {
            color: $white-color !important;
          }

          &:hover {
            background-color: #555555;
          }
        }
      }
    }


    }
  }

  /* ------------------------------ MOBILE : MENU ----------------------------- */
  .header-menus {
    @media (max-width: 1024px) {
      order: 1;
      width: 39px;

      nav.nav-dropdown-desktop {
        display: none;
      }

      .nav-mobile {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $white-color;
        &.visible {
          animation: fadeIn 300ms ease-out;
        }
        svg {
          width: 25px;
          height: 25px;
        }

        &:hover,
        &:focus,
        &.active {
          color: #5c6fc5;
        }

        nav.nav-dropdown-mobile {
          position: fixed;
          top: 0;
          padding-top: 60px;
          display: flex;
          background-color: #f8f8f8;
          height: 100vh;
          width: 100%;
          left: 0;
          overflow-y: scroll;
          z-index: 1;
          /* ------------------------------- MOBILE : Navigation Menu -------------------------------- */

          .close-btn {
            position: absolute;
            top: 10px;
            right: 10px;
            border: none;
            padding: 5px 6px;
          }

          ul.nav-menu {
            display: flex;
            width: 100%;

            flex-flow: column nowrap;

              li.active-menu {
                margin: 0;
                background-color: $optional-color;
                border-top: 1px solid $main-color;
              }
              li.active-menu:first-child {
                border: none;
              }

              .list-menu {
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                transition: all 0.3s ease-out;

                .item-menu {
                  padding: 15px 0px;
                  color: $font-color;
                  display: flex;
                  width: 100%;
                  overflow-x: hidden;
                  justify-content: space-between;
                  align-items: center;

                  text-transform: capitalize;
                  transition: all 0.5s ease-out;

                  &:active,&:focus, &:hover {
                    color: #fff ;
                  }
                  span {
                    text-transform: uppercase;
                    font-family: $main-font-family;
                    font-size: $font-size;
                    font-weight: 600;
                    margin: 0px;
                    padding-left: 20px;
                  }

                  svg {
                    margin-right: 20px;
                    transition: all 0.5s ease-out;
                    width: 10px;
                    height: 10px;
                  }

                }

                a {
                  color: $font-color;
                }
                &:hover, &:focus, &.active {
                  background-color: $hover-color;
                }
            }
          }
          /* ---------------------------- MOBILE : Navigation SubMenu ------------------------------ */
          ul.nav-sub-menu {
            background: $optional-color;
            width: 100%;
            display: flex;
            flex-flow: column nowrap;
            box-shadow: none;
            border: none;

            li.active-sub-menu {
              width: 100%;
              left: 3em;

              border-top: 1px solid $main-color;
              text-transform: capitalize;
              transition: all 0.5s ease-out;

              a {
                text-transform: uppercase;
                font-family: $main-font-family;
                font-size: $font-size;
                font-weight: 500;
                color: $font-color;

                .list-sub-menu {
                  padding: 1em 10%;
                }
              }

              &:hover, &:focus, &.active {
                background-color: $hover-color;
                svg, span.sub-menu {
                  color: white !important;
                }
              }
            }
          }
        }
      }
    }
  }
}

/* ------------------------------ HEADER ACTION ----------------------------- */

.header-action {
  @media (max-width: 900px) {
    order: 3;
  }
  order: 3;
  display: flex;
  justify-content: flex-end;
  width: auto;
  align-items: center;
  .more-menu {
    @media (max-width: 1024px) {
      display: none;
    }
    cursor: pointer;
    li {
      svg {
        color: $white-color;
        transition:all 0.3s ease;
        &:hover { 
          color: rgb(92, 111, 197);
        }
      }
    }
  }
  ul.action-box {
    display: flex;
    align-items: center;
    list-style: outside none none;

    li.action-item {
      // border-right: 1px solid $font-color;
      line-height: 16px;
      padding: 0;
      margin-right: 0px;
      font-size: 14px;

      a {
        color: $white-color;
      }

      &:hover {
        color: $hover-color;
      }
    }

    li:last-child {
      border: 0px;
      padding-right: 5px;
    }
  }
  /* --------------------------------- SIDEBAR -------------------------------- */

  .nav-sidebar {
    color: $font-color;
    &:hover {
      color: $hover-color;
    }
    svg {
      margin-left: 12px;
      padding-top: 2px;
      width: 20px;
      height: 20px;
    }

    .sidebar-fixed {
      &.visible {
        animation: slideIn 1s ease-out;
      }
      &.invisible {
        right: -350px;
        animation: slideOut 2s ease-out;
      }

      height: 100%;
      min-width: 300px;
      width: 0;
      position: fixed;
      z-index: 1;
      top: 0;
      right: 0;
      background-color: $optional-color;
      border-left: 1px solid $main-color;
      box-shadow: 0px 1px 8px $main-color;
      overflow-x: hidden;
      transition: 0.5s;
      padding-top: 60px;



      li {
        display: block;
        border-bottom: 1px solid $main-color;
        width: 100%;
        padding: 0px;
        margin: 0px;
        transition: all 0.5s ease-out;

        .sidebar-item {
          display: flex;
          align-items: center;
          background-color: transparent;
          transition: all 0.5s ease-out;
          border: 0;
          padding: 15px 25px;

          svg {
            width: 25px;
            height: 25px;
            margin-right: 15px;
            color: $font-color;
          }

          a span {
            padding-top: 5px;
          }

          a {
            text-transform: uppercase;
            color: $font-color;
            font-size: 15px;
            display: flex;
            align-items: center;
            font-weight: 300;
            transition: all 0.5s ease-out;
          }

          &:hover {
            a,
            svg {
              color: $main-color !important;
            }
            background-color: $hover-color;
          }
        }
      }

    }


    button.close-btn {
      position: absolute;
      top: 20px;
      right: 20px;
      font-size: 36px;
      margin-left: 50px;
      background-color: transparent;
      color: $main-color;
      padding: 0px;
      margin: 0px;
      border: none;

      &:hover {
        color: $hover-color;
      }
    }
  }
}

/* ------------------------------ HEADER STORIES ----------------------------- */

.header-stories-fixed {
  margin-top: 0px; //64px
  background-color: #f8f8f8;
  position: relative;
  border-bottom: 1px solid #e4e4e4;
  padding: 0px 10px;

  &.--dark {
    background-color: $fade-dark-color;
    border-bottom: none;
  }

  @media (max-width: 767px) {
    padding: 0;
  }
  @media (min-width: 900px) {
      .grid-container {
        grid-template-columns: 1fr 3fr 2fr;
        grid-template-areas: "topic typing social";
      }
      .social-box {
        width: 100%;
        padding: 10px 0px;
      }
    }
    @media (max-width: 900px) {
      .grid-container {
        display: grid;
        grid-template-columns: 1fr 4fr;
        grid-template-areas:
        "topic typing"
        "social social";
      }

    .social-box {
        justify-content: flex-start !important;
        padding: 10px;
        width: 100%;
    }

    .Typewriter {
      white-space: pre-wrap;
    }
  }
    @media (max-width: 678px) {
      .grid-container {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-areas:
        "topic topic"
        "typing typing"
        "social social";
      }

      .social-box {
        justify-content: flex-start !important;
        padding: 10px;
        width: 100%;
      }

      .topic-box {
        font-size: 12px;
        margin-right: 15px !important;
      }
      .Typewriter {
        padding: 0px 10px;
      }
  }

  .grid-container {
    display: grid;
  }

  .topic-box {
    grid-area: topic;
    position: relative;
    display: flex;
    font-weight: 600;
    font-size: 14px;
    text-transform: uppercase;
    color: #ffffff;
    height: 40px;
    padding: 0px 20px;
    align-items: center;
    justify-content: center;
    font-family: $main-font-family;
    z-index: 2;
    margin: 10px 30px 10px 10px;
    white-space: nowrap;


    &:after {
      content: "";
      height: 100%;
      width: 100%;
      z-index: -1;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      background-color: $second-color;
    }
    &:before {
      z-index: 1;
      content: '';
      top: 0;
      right: -8px;
      position: absolute;
      width: 0;
      height: 0;
      border-top: 8px solid $second-color;
      border-right: 8px solid transparent;
    }
  }
  .typing-box {
    grid-area: typing;
    align-self: center;

    .Typewriter {
      display: flex;
      max-width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;


      .Typewriter__cursor {
        color: $hover-color;
        margin-left: 10px;
      }

      @media (max-width: 450px) {
      }

    }
  }
  .social-box {
    grid-area: social;
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: center;

    p {
      font-weight: 600;
      font-size: 12px;
      padding-right: 15px;
    }
      .header-social	{
        svg {
          height: 20px;
          width: 20px;
          padding: 3px;
          background-color: $paragraph-color;
          border-radius: 5px;
          color: $white-color;
          transition: .3s;
          &:hover {
            background-color: $hover-color;
          }
        }
      }
    }
}


/* ------------------------------- HEADER NEWS ------------------------------ */

.header-news-fixed {
  padding: 10px;
  background-color: #fff;

  .header-news ul {
    list-style: outside none none;

    li {
      border-right: 1px solid #dcdcdc;
      line-height: 16px;
      padding-right: 15px;
      margin-right: 15px;
      font-size: 14px;

      svg {
        color: $hover-color;
        height: 20px;
        width: 20px;
      }

      a {
        display: flex;
        align-items: center;
        color: $font-color;
      }
      span {
        padding-top: 2px;
        padding-left: 10px;
      }
    }

    li:last-child {
      border: 0px;
    }
  }
}
